










































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { RouteName } from '@/router/route-name'
import { XDataColumn } from '@/types'
import { formatCount, formatDateTime } from '@/utils/formatters'
import { useI18n } from '@/utils/useI18n'

import XDataList from '@/components/ui/XDataList.vue'
import SalesOn from '@/views/MySupplies/SalesOn.vue'
import { DATE_TIME_DEFAULT } from '@/constants/formats'

export default defineComponent({
  inheritAttrs: false,

  components: { SalesOn, XDataList },

  props: {
    offers: {
      type: Array as PropType<any[]>
    },
    loading: Boolean
  },
  setup () {
    const i18n = useI18n()

    const columns = computed<XDataColumn<any>[]>(() => {
      return [
        {
          prop: 'photo',
          name: i18n.t('Photo'),
          minWidth: 82
        },
        {
          prop: 'name',
          name: i18n.t('Good\'s name'),
          minWidth: 140
        },
        {
          prop: 'status',
          name: i18n.t('Status'),
          minWidth: 82
        },
        {
          prop: 'onSaleAmount',
          name: i18n.t('On sale amount, pcs'),
          minWidth: 82,
          formatter: (item) => formatCount(item.onSaleAmount)
        },
        {
          prop: 'inTransitAmount',
          name: i18n.t('In transit amount, pcs'),
          minWidth: 82,
          formatter: (item) => formatCount(item.inTransitAmount)

        },
        {
          prop: 'soldAmount',
          name: i18n.t('Sold amount, pcs'),
          minWidth: 82,
          formatter: (item) => formatCount(item.soldAmount)
        },
        {
          prop: 'GMV',
          name: i18n.t('GMV'),
          minWidth: 82
        },
        {
          prop: 'marginalityPercentage',
          name: i18n.t('Marginality, %'),
          minWidth: 82,
          formatter: item => item.soldPercentage + '%'
        },
        {
          prop: 'nearestDeliveryDetails',
          name: i18n.t('Nearest delivery'),
          minWidth: 82,
          align: 'end',
          formatter: item => {
            return item.nearestDeliveryDetails.date
              ? formatDateTime(item.nearestDeliveryDetails.date, DATE_TIME_DEFAULT)
              : i18n.t('Not scheduled')
          }
        }
      ]
    })

    const getSupplyLocation = (offer: any) => ({
      name: RouteName.OFFER_REVIEW,
      params: { offerId: String(offer.id) }
    })

    return {
      columns,
      getSupplyLocation
    }
  }
})

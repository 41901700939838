







































import Vue from 'vue'

import XDatePicker from '@/components/ui/XDatePicker.vue'
import XSwitcher from '@/components/ui/XSwitcher.vue'
import OffersList from '@/views/MyOffers/OffersList.vue'

import STATUSES from '@/dictionaries/supplyRequestStatuses'
import { RouteName } from '@/router/route-name'

import offers from '@/mock/offers'

export default Vue.extend({
  name: 'MyOffers',

  components: { XDatePicker, XSwitcher, OffersList },

  data: () => ({
    offers,

    dateCreated: {
      start: Date.now() - 10000000000,
      end: Date.now() - 10000000000
    },

    search: '',

    statuses: STATUSES,
    selectedStatuses: [],

    totalCount: 2,

    isOnSaleOnly: false
  }),

  computed: {
    breadcrumbs () {
      return [{
        text: this.$t('My goods'),
        to: { name: RouteName.OFFERS }
      }]
    }
  },

  methods: {
    onSearch () {
      console.log(this.search)
    }
  }
})


import { render, staticRenderFns } from "./OffersList.vue?vue&type=template&id=29a9f293&scoped=true&"
import script from "./OffersList.vue?vue&type=script&lang=ts&"
export * from "./OffersList.vue?vue&type=script&lang=ts&"
import style0 from "./OffersList.vue?vue&type=style&index=0&id=29a9f293&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29a9f293",
  null
  
)

export default component.exports
export default [
  {
    id: 601,
    name: 'Складное силиконовое ведро',
    supplyRequestIds: ['170-0104', '012-1403'],
    created: '2021-01-04T15:57:49.290Z',
    modified: '2022-04-14T07:31:49.290Z',
    averagePrice: 933,
    status: 'on_sale',
    rating: 5,
    reviewAmount: 156,
    vendor_code: 517620442,
    brand: ['BestHome'],
    category: {
      id: 1,
      name: 'товары для дома'
    },

    onSaleAmount: 1177,
    inTransitAmount: 2000,
    soldAmount: 1723,

    gmv: 1608717,

    marginalityPercentage: 727,

    nearestDeliveryDetails: {
      date: null,
      supplyId: null
    },

    salesOn: [1, 2, 9],

    purchasePrice: 99.93,
    soldPercentage: 59.4,

    salesDetails: {
      ali: {
        soldAmount: 829,
        rating: 4.8,
        reviewAmount: 74,
        soldSummary: 748587,
        averageCheck: 903,
        shareOfSales: 48.2
      },
      wildberries: {
        soldAmount: 574,
        rating: 5,
        reviewAmount: 26,
        soldSummary: 559650,
        averageCheck: 975,
        shareOfSales: 33.3
      },
      ozon: {
        soldAmount: 320,
        rating: 5,
        reviewAmount: 14,
        soldSummary: 300480,
        averageCheck: 939,
        shareOfSales: 18.5
      }
    },

    deliveryHistory: [
      {
        id: '170-0104',
        amount: 2000,
        created: '2021-01-04T18:57:00.000Z',
        /* дата поставки? */
        delivered: '2021-01-20T18:05:00.000Z',
        status: 'on_customs'
      },
      {
        id: '012-1403',
        amount: 1700,
        created: '2021-03-14T18:57:00.000Z',
        /* дата поставки? */
        delivered: '2021-03-20T18:05:00.000Z',
        status: 'on_customs'
      },
      {
        id: '004-1102',
        amount: 1200,
        created: '2021-02-11T18:57:00.000Z',
        /* дата поставки? */
        delivered: '2021-02-22T18:05:00.000Z',
        status: 'on_customs'
      }
    ],

    quantityByStores: [
      {
        id: 1,
        name: 'Наро-Фоминск',
        fulfillment: 'XWAY',
        model: 'FBS',
        freeAmount: 482,
        reserve: 15
      },
      {
        id: 2,
        name: 'Коледино',
        fulfillment: 'wildberries',
        model: 'FBO',
        freeAmount: 270,
        reserve: 0
      },
      {
        id: 3,
        name: 'Чехов',
        fulfillment: 'Cianiao',
        model: 'FBS',
        freeAmount: 345,
        reserve: 27
      },
      {
        id: 4,
        name: 'Зеленоград',
        fulfillment: 'ozon',
        model: 'FBO',
        freeAmount: 80,
        reserve: 9
      }
    ],

    images: [
      'https://notion-emojis.s3-us-west-2.amazonaws.com/prod/svg-twitter/1faa3.svg',
      'https://plastic-walker-0c5.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F5bfad9fa-dcda-4a0b-b9f0-eed55378078a%2F%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA_%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0_2022-04-28_%D0%B2_16.19.43.png?table=block&id=74bd74cd-9ee9-4cf8-abf2-969a26eb2864&spaceId=7ad7c9b8-a63c-4a60-9776-8cb3ff55577f&width=2000&userId=&cache=v2',
      'https://plastic-walker-0c5.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fdc197ed9-1acb-4fdc-b3e4-7303beafab7e%2F%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA_%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0_2022-04-28_%D0%B2_16.19.54.png?table=block&id=a788c3ca-1c28-4cd0-936c-c142ab83d72b&spaceId=7ad7c9b8-a63c-4a60-9776-8cb3ff55577f&width=2000&userId=&cache=v2'
    ]
  }
]
